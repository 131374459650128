import React from "react";

const experienceContent = [
  {
    year: "   2020 - Present",
    position: " Photo-Videographer and Graphics Designer",
    compnayName: "Achiever Properties L.L.C - Dubai",
  },
  {
    year: "2019 - 2020",
    position: " Digital Marketing Executive",
    compnayName: "Arta Real Estate Brokers - Dubai ",
  },
  {
    year: "2016 - 2019",
    position: "Graphics Designer and Photographer",
    compnayName: "Gulf Colours L.L.C - Qatar",
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img src="img/exp.png" alt="icon" />
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
